import { render, staticRenderFns } from "./AzVenues.vue?vue&type=template&id=5543d146&scoped=true&"
import script from "./AzVenues.vue?vue&type=script&lang=ts&"
export * from "./AzVenues.vue?vue&type=script&lang=ts&"
import style0 from "./AzVenues.vue?vue&type=style&index=0&id=5543d146&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5543d146",
  null
  
)

export default component.exports