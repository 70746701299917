




























































































































































import Vue, { PropType } from "vue";
import {
  SubscriptionProductsData,
  VenueData,
  VenueSubscriptionData,
} from "@/types";
import StarRating from "vue-star-rating";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "venue-list-item" as string,

  components: {
    StarRating,
  },

  props: {
    venue: Object as PropType<VenueData>,
    venueSubscription: Object as PropType<VenueSubscriptionData>,
    subscriptionProducts: Array as PropType<Array<SubscriptionProductsData>>,
  },

  computed: {
    ...mapGetters("playlistModule", {
      venueTypes: "GET_VENUE_TYPES",
    }),
    getSubscriptionType(): number {
      if (!this.venueSubscription) return;

      const product: SubscriptionProductsData = this.subscriptionProducts.find(
        p => p.id === this.venueSubscription.stripeProductWithPriceId
      );
      if (product) {
        return product.songorooPackageType;
      } else if (
        this.venueSubscription.isCorporate &&
        !this.venueSubscription.stripeProductWithPriceId
      ) {
        return 3;
      } else {
        return -1;
      }
    },
    isTrial() {
      if (!this.venueSubscription) {
        return false;
      }
      return this.venueSubscription.isTrial;
    },
    Playlist() {
      if (
        !this.venueTypes.some(x => x.venue_type_id === this.venue.venue_type_id)
      ) {
        return "Custom Playlist";
      } else {
        return this.venueTypes
          .filter(x => x.venue_type_id === this.venue.venue_type_id)
          .filter(
            x => x.venue_type_alias_id === this.venue.venue_type_alias_id
          )[0].name;
      }
    },
    isSubscriptionExpired(): boolean {
      if (!this.venueSubscription) return false;

      const currentDate = Math.floor(Date.now() / 1000);
      const expiredDate = Number(this.venueSubscription.subscriptionEndsAt);

      return (
        currentDate >= expiredDate ||
        this.venueSubscription.subscriptionStatus === "canceled"
      );
    },
    isSubscriptionIncomplete(): boolean {
      if (!this.venueSubscription) return false;

      return this.venueSubscription.subscriptionStatus == "incomplete";
    },
    isSubscriptionIncompleteExpired(): boolean {
      if (!this.venueSubscription) return false;

      return this.venueSubscription.subscriptionStatus == "incomplete_expired";
    },
    isSubscriptionActive(): boolean {
      return !this.isSubscriptionExpired && !this.isSubscriptionIncomplete && !this.isSubscriptionIncompleteExpired;
    },
  },

  methods: {
    goToVenueDetail(venue): void {
      if (venue.mock_value) return;

      this.$router.push({
        path: "/venue-detail/" + venue.id,
        params: { id: venue.id },
      });
    },
    goToPlaylist(venue) {
      localStorage.setItem("venue_id", venue.id);
      this.$router.push("/select-playlist");
    },
    insertVenueImage(value: string): string {
      if (!value) return "";
      return `data:image/jpeg;charset=utf-8;base64, ${value}`;
    },
  },
});
